import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const FileField = ({fieldClass, labelClass, type, name, value, required, placeholder, handlechange, accept, label, grpmd}) => (
  <Form.Group className="input-box upload"  controlId={"validation" + name}>
    { label
      ? <Form.Label className={`form-label`}>{label}{required ? '*' : ''}</Form.Label>
      : ''
    }
    <div>
    <label for="custom-file" className={fieldClass}>
      <Form.Control
        required={required}
        type={type}
        name={name}
        value={value}
        onChange={handlechange}
        accept={accept}
        id="custom-file"
        className='file'
      />
      <span>{placeholder}</span></label>
    </div>
  </Form.Group>
);

export default FileField;